/* basic structure */
.l-grid {
  overflow: hidden;
}

.l-clear {
  clear: both;
}

.l-one-whole {
  width: 100%;
}

.l-one-half {
  width: 50%;
}

.l-one-third {
  width: 33.333%;
}

.l-one-quarter {
  width: 25%;
}

.l-panorama,
.l-square,
.l-landscape {
  &:before {
    content: "";
    display: block;
  }
}

.l-panorama {
  &:before {
    padding-top: 50%;
  }
}

.l-square {
  &:before {
    padding-top: 100%;
  }
}

.l-landscape {
  &:before {
    padding-top: 75%;
  }
}



@include breakpoint(mobile) {
  .l-one-whole-mobile {
    width: 100%;
  }

  .l-one-half-mobile {
    width: 50%;
  }

  .l-one-third-mobile {
    width: 33.333%;
  }

  .l-one-quarter-mobile {
    width: 25%;
  }

  .l-panorama-mobile {
    &:before{
      padding-top: 50%;
    }
  }

  .l-square-mobile {
    &:before {
      padding-top: 100%;
    }
  }

  .l-landscape-mobile {
    &:before {
      padding-top: 75%;
    }
  }
}

.l-absolute-centered {
  position: absolute;
  top: 50%; left: 10%; right: 10%;
  max-height: 80%;
  transform: translate(0, -50%);
}


@import
    "sensible/grid",
    "sensible/responsive-visibility";


@include sensibleGrid (
    $modern: false,
    $gutter: 10px,
    $base-font-size: 14px,
    $slug: "l-",
    $pushClasses: false,
    $gutterDirection: "right",
    $gridWidths: (
        "one-whole"         : 100%,
        "one-half"          : 50%,
        "one-quarter"       : 25%,
        "three-quarters"    : 75%,
        "one-third"         : 33.333%,
        "two-thirds"        : 66.666%,
        "one-fifth"         : 20%,
        "four-fifths"       : 80%,
        "one-sixth"         : 16.666%,
        "five-sixths"       : 83.333%
    ),
    $gridBreakpoints: "not-tablet" "tablet" "tablet-portrait" "not-mobile" "mobile" "mobile-portrait" "print",
    $rounding: 0.02%
);

$visibility-breakpoints: "mobile-portrait" "mobile" "tablet-portrait" "tablet" "not-tablet" "print";

@include responsive-visibility( "only-mobile" "only-tablet" "only-desktop" , true);

@include responsive-visibility (
  $visibility-breakpoints,
  $overwrite: false,
  $displayTypes: "inline" "inline-block"
);

// @include responsive-visibility("only-mobile" "only-tablet" "only-desktop", true);

.l-grid-cell.padding-bottom {
    padding-bottom:$s-gutter-m;
}
.l-grid-cell.padding-bottom-l {
    padding-bottom:20px;
}
.l-grid-cell.padding-form {
    padding-bottom:$s-gutter-m;
    // padding-left:$s-gutter-m - 2px;
}
.inner-form-cell {
  border-bottom: 1px solid black;
  padding-top: 6px;
  padding-bottom: 2px;
  position: relative;
  margin-bottom: $s-gutter-m;

  select {
    position: relative;
    top: -3px;
  }
  input[type=radio] {
    position: relative;
    top: -1px;
  }
}


.l-site-width.left-align {
    margin:0 30px;
    position:relative;
    overflow:hidden;
    visibility:hidden;
    max-width: 1100px;
    @include breakpoint(mobile) {
        margin:10px;
    }
    &.animate-in {
      animation: introcontainer 3s 1 normal cubic-bezier(0.085, 0.405, 0.305, 1.000) forwards;
    }
}

@keyframes introcontainer {
    0% {
        // left: 200px;
        transform: translateX(200px);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        // left: 0;
        transform: translateX(0);
        opacity: 1;
    }
}

.no-js .l-site-width.left-align {
    visibility:visible;
}

.l-whole-width {
    width:100%;
}

.l-grid-cell.nested {
    padding-right:0 !important;
}

.l-grid-cell.margin-fix {
    margin-right:-5px;
}

.icon-label {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    top: -10px;
}
.icon-small {
    display: inline-block;
    // width: 17px;
    // height: 17px;
    // background-size: contain;
    margin-right: .2em;
    // vertical-align: middle;
    font-size: 17px;
}
.icon-medium {
    @extend .icon-small;
    // width: 24px;
    // height: 24px;
    font-size: 24px;
}
.icon-large {
    @extend .icon-small;
    // width: 32px;
    // height: 32px;
    font-size: 32px;
}
