$c-sgblue: #33aaee;

.styleguide {

  .styleguide-header-wrap {
    background-color:#fff;
    // max-width: 980px;
    // border-bottom:2px solid #fff;

    .styleguide-header {
      padding:40px 0 20px 0;
      color:#000;
    }
  }

  h2 + article {
    // margin-top: 20px;
  }

  h1.sg-h1, h2.sg-h2, h3.sg-h3, h4.sg-h4 {
    color:#000;
    font-family: $base-font-family;
    font-weight: normal;
    margin-bottom:10px;
    letter-spacing: -.02em;
  }
  h1.sg-h1 {
    font-weight:normal;
    font-size:76px;
    margin-bottom:0;
    left: -3px;
    position: relative;
  }
  h2.sg-h2 {
    font-size:40px;
    font-weight:normal;
    margin-bottom: 0;
    line-height: 1;

    &--header {
      font-size: 14px;
      margin-bottom: -2px;
    }
  }
  h3.sg-h3 {
    margin-bottom:10px;
    font-size:14px;
    // font-weight: normal;
  }
  h4.sg-h4 {
    color:inherit;
    margin:0;
    font-weight:normal;
    font-size:14px;
  }
  p.sg-p {
    max-width:1100px;
    color:#000;
    font-size:18px;
  }
  a.sg-template-link, a.sg-template-link {
    font-size:14px;
    display:inline-block;
    padding:10px;
    border:1px solid #000;
    margin-right:10px;
    text-decoration:none !important;
    color:#000;
    &:hover {
      border-color:#555;
      background-color:#111;
    }
  }
  .sg-templates {
    margin-bottom: 60px;
  }
  .sg-template-label {
    font-size: 1em;
    color: $c-sgblue;
    pointer-events: none;
    display: block;
    position: absolute;
    bottom: -18px;
    left: 0;
    text-align: left;
  }
  .sg-template-list {
    padding:0;
    list-style-type: none;
    margin-bottom: 30px;
    margin-top:11px;

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    li {
      font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
      position: relative;

      &::before {
        content: "— ";
        color: $c-sgblue;
      }

      a {
        // display: block;
        text-decoration: none;
        // border-bottom: 1px solid #ddd;
        padding: .2em 0;
        // margin-right: 1em;
        color: $c-sgblue;
        // border-radius: 1.5em;

        &:hover {
          // border-color: #000;
          color: #000;
        }
      }

      img {
        width: 120px;
        height: auto;
      }

      &.sg-template-imgitem {
        display: block;
        float: left;
        margin-right: 20px;
        margin-bottom: 30px;

        &::before {
          content: "";
        }
        a {
          display: block;
          border: 1px solid #ddd;
          padding: 0;
          // margin-right: .5em;

          &:hover {
            border-color: $c-sgblue;
          }
        }
      }
    }
  }
  .sg-module-list {
    padding:0;
    margin-top: 0;
    li {
      font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
      margin-bottom:5px;
      // display: block;

      // &::before {
      //   content: "— ";
      //   color: $c-sgblue;
      // }

      a {
        // display: block;
        text-decoration: underline;
        // border-bottom: 1px solid #ddd;
        padding: .2em 0;
        margin-right: 1em;
        margin-bottom: .7em;
        color: $c-sgblue;
        // border-radius: 1.5em;
        &:hover {
          // border-color: #000;
          // color: #000;
          text-decoration: underline;
          color: darken($c-sgblue, 15%);
        }
      }
    }
  }
  .sg-article {
    margin-bottom:40px;

    &--modules {
      margin-bottom: 56px;
    }

    a {
      font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
      color:#000;
      text-decoration:underline;
    }
    a:hover {
      text-decoration:none;
    }
    p {
      color: #000;
      font-size: 1em;
      line-height: 1.5;
      max-width: 750px;
    }
  }

  .sg-element-wrap {
    max-width:1100px;
    margin-bottom:80px;
    margin-top:40px;
  }

  .sg-element-head-wrap {
    width:100%;
  }
  .sg-element-head {
    background-color:#000;
    // border-top: 3px solid black;
    // border-bottom: 1px solid #888;
    padding:15px 25px;
    color:#fff;
    position:relative;
    box-sizing:border-box;
  }
  a.sg-code-link {
    display:block;
    position:absolute;
    right:0;
    top:0;
    height:100%;
    padding:0 30px;
    font-size:14px;
    // text-transform:uppercase;
    color:$c-sgblue;
    font-family:$base-font-family-sec;
    font-weight:normal;
    line-height:45px;
    text-decoration: underline;
    // border-left:1px solid #fff;

    &:hover {
      // color: #000;
    }
  }
  .sg-code-wrap {
    background:#000;
    border-top:1px solid #fff;
    color:#000;
    padding:20px;
    overflow:hidden;
    box-sizing:border-box;

    pre {
      margin:0;
      white-space:pre-wrap;
    }
  }

  .sg-comment {
    margin-top:40px;
    padding:10px 0;
    // border:2px solid #ddd;
    // background-color:#fbfbfb;
    box-sizing:border-box;
    p {
      margin:10px 0;
      color: $c-sgblue;
    }
  }
}

p.sg-lead {
  margin-bottom: 7px;
}

a.sg-dl-link:link, a.sg-dl-link:visited {
  display:inline-block;
  text-decoration:none !important;
  padding:10px 25px;
  // border:2px solid $c-sgblue;
  background-color: $c-sgblue;
  color: #fff;
  // background: #44bbff;
  font-family: $base-font-family-sec;
  margin-bottom:40px;
  // color: $c-sgblue;

  &:hover {
    // background-color:#eee;
    // border-color: #000;
    // color: #000;
    background-color: darken($c-sgblue, 7%);
  }
}

/* Pretty printing styles. Used with prettify.js. */
/* Vim sunburst theme by David Leibovic */

pre .str, code .str { color: #65B042; } /* string  - green */
pre .kwd, code .kwd { color: #E28964; } /* keyword - dark pink */
pre .com, code .com { color: #AEAEAE; font-style: italic; } /* comment - gray */
pre .typ, code .typ { color: #89bdff; } /* type - light blue */
pre .lit, code .lit { color: #3387CC; } /* literal - blue */
pre .pun, code .pun { color: #fff; } /* punctuation - white */
pre .pln, code .pln { color: #fff; } /* plaintext - white */
pre .tag, code .tag { color: #89bdff; } /* html/xml tag    - light blue */
pre .atn, code .atn { color: #bdb76b; } /* html/xml attribute name  - khaki */
pre .atv, code .atv { color: #65B042; } /* html/xml attribute value - green */
pre .dec, code .dec { color: #3387CC; } /* decimal - blue */


/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE; } /* IE indents via margin-left */
li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,li.L3,li.L5,li.L7,li.L9 { }

@media print {
  pre .str, code .str { color: #060; }
  pre .kwd, code .kwd { color: #006; font-weight: normal; }
  pre .com, code .com { color: #600; font-style: italic; }
  pre .typ, code .typ { color: #404; font-weight: normal; }
  pre .lit, code .lit { color: #044; }
  pre .pun, code .pun { color: #440; }
  pre .pln, code .pln { color: #000; }
  pre .tag, code .tag { color: #006; font-weight: normal; }
  pre .atn, code .atn { color: #404; }
  pre .atv, code .atv { color: #060; }
}


.sg-icon {
  display:inline-block;
  padding:30px 35px 50px 35px;
  // border:1px solid #eee;
  position:relative;
  margin:5px;

  i {
    font-size:28px;
  }

  .sg-icon-label {
    font-family:$base-font-family-sec;
    font-size:11px;
    position:absolute;
    width:100%;
    bottom:0;
    left:0;
    padding:6px 2px;
    text-align:center;
    // border-top:1px solid #eee;
    color: $c-sgblue;
  }

  .icon-medium {
    margin-right: 0;
  }
}
