/**
 * Typography settings
 */

 $base-font-size: 16px;
 $line-height-ratio: 1.3;

 $base-font-family: 'HelveticaNeueLTStd-Blk', Helvetica, Arial, sans-serif;
 $base-font-family-sec: 'Helvetica Neue', Helvetica, Arial, sans-serif;
 $f-default: $base-font-family;

/*
 * Helvetica Neue Black – MyFonts.com
 */

/**
 * @license
 * MyFonts Webfont Build ID 2846025, 2014-07-15T07:49:48-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Helvetica Neue 95 Black by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/neue-helvetica/helvetica-95-black/
 * Copyright: Copyright &#x00A9; 1988, 1990, 1993, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 * Licensed pageviews: 250,000
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2846025
 *
 * © 2014 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2b6d49");


@font-face {font-family: 'HelveticaNeueLTStd-Blk';src: url('../assets/fonts/2B6D49_0_0.eot');src: url('../assets/fonts/2B6D49_0_0.eot?#iefix') format('embedded-opentype'),url('../assets/fonts/2B6D49_0_0.woff') format('woff'),url('../assets/fonts/2B6D49_0_0.ttf') format('truetype');}

/* ICONS */

@font-face {
    font-family: 'icomoon';
    src:    url('../assets/fonts/icomoon.eot?1ru54k');
    src:    url('../assets/fonts/icomoon.eot?1ru54k#iefix') format('embedded-opentype'),
        url('../assets/fonts/icomoon.ttf?1ru54k') format('truetype'),
        url('../assets/fonts/icomoon.woff?1ru54k') format('woff'),
        url('../assets/fonts/icomoon.svg?1ru54k#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #000;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-attachment:before {
    content: "\e900";
}
.icon-audio:before {
    content: "\e901";
}
.icon-bzu:before {
    content: "\e902";
}
.icon-calendar:before {
    content: "\e903";
}
.icon-chat:before {
    content: "\e904";
}
.icon-check:before {
    content: "\e905";
}
.icon-check2:before {
    content: "\e906";
}
.icon-close:before {
    content: "\e907";
}
.icon-close2:before {
    content: "\e908";
}
.icon-document:before {
    content: "\e909";
}
.icon-down:before {
    content: "\e90a";
}
.icon-download:before {
    content: "\e90b";
}
.icon-edit:before {
    content: "\e90c";
}
.icon-edit2:before {
    content: "\e90d";
}
.icon-folder:before {
    content: "\e90e";
}
.icon-heart:before {
    content: "\e90f";
}
.icon-help:before {
    content: "\e910";
}
.icon-home:before {
    content: "\e911";
}
.icon-image:before {
    content: "\e912";
}
.icon-information:before {
    content: "\e913";
}
.icon-left:before {
    content: "\e914";
}
.icon-link:before {
    content: "\e915";
}
.icon-location:before {
    content: "\e916";
}
.icon-lock:before {
    content: "\e917";
}
.icon-mail:before {
    content: "\e918";
}
.icon-mailRead:before {
    content: "\e919";
}
.icon-map:before {
    content: "\e91a";
}
.icon-media:before {
    content: "\e91b";
}
.icon-minus:before {
    content: "\e91c";
}
.icon-minus2:before {
    content: "\e91d";
}
.icon-mute:before {
    content: "\e91e";
}
.icon-phone:before {
    content: "\e91f";
}
.icon-photo:before {
    content: "\e920";
}
.icon-plus:before {
    content: "\e921";
}
.icon-plus2:before {
    content: "\e922";
}
.icon-print:before {
    content: "\e923";
}
.icon-question:before {
    content: "\e924";
}
.icon-right:before {
    content: "\e925";
}
.icon-rss:before {
    content: "\e926";
}
.icon-search:before {
    content: "\e927";
}
.icon-settings:before {
    content: "\e928";
}
.icon-star:before {
    content: "\e929";
}
.icon-transfer:before {
    content: "\e92a";
}
.icon-trash:before {
    content: "\e92b";
}
.icon-unlocked:before {
    content: "\e92c";
}
.icon-up:before {
    content: "\e92d";
}
.icon-user:before {
    content: "\e92e";
}
.icon-video:before {
    content: "\e92f";
}
.icon-volumeDown:before {
    content: "\e930";
}
.icon-volumeUp:before {
    content: "\e931";
}
.icon-wifi:before {
    content: "\e932";
}

