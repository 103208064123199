/**
 * Define the breakpoints for vendor/sensible/mediaqueries
 * They are defined the mobile first way
 */

 $breakpoints: (
   "mobile-portrait"     : "only screen and (max-width:400px)",
   "mobile"              : "only screen and (max-width:740px)",
   "not-mobile"          : "only screen and (min-width:741px)",
   "only-mobile"         : "only screen and (max-width:740px)",
   "tablet-portrait"     : "only screen and (max-width:850px)",
   "tablet"              : "only screen and (max-width:1050px)",
   "only-tablet"         : "only screen and (min-width:741px) and (max-width:1051px)",
   "not-tablet"          : "only screen and (min-width:1051px)",
   "desktop"             : "only screen and (min-width:1051px)",
   "only-desktop"        : "only screen and (min-width:1051px)",
   "retina"              : "only screen and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5)",
   "highres"             : "print, (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)",
   "print"               : "print"
 );
/*
 * Variables that are used on the project.
 * Never use a color that is not in here
 *
 * Namespace:
 *  f: font
 *  c: color
 *  s: size
 *  i: icons & sprites
 *  a: animations & transitions
 */
// @import "bonescss/0.settings";

// Fonts


$s-design-width: 1100px;


// spacings
$s-gutter: 20px;
$s-gutter-half: $s-gutter * .5;
$s-gutter-double: $s-gutter * 2;
$s-gutter-s: 5px;
$s-gutter-m: 10px;
$s-gutter-l: 20px;

// sizes

// sprites & icons

// animations
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
