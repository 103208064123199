/**
 * Color variables
 */

 $c-eigengrau:      #16161D;
 $c-graphite:       #666;
 $c-silver:         #eee;
 $c-white:          #fff;
 // $c-red:            #E00202;

 $c-cover:          rgba(0,0,0,0.5);

//

 $c-grey-lightest: #f7f7f7;
 $c-grey-light: #ddd;
 $c-main: #000;
 // $c-highlight: #1BE657;
 $c-highlight: #ff0066;
 $c-red: #D0021B;
