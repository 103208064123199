.home-block {
  display:block;
  color:$c-main;
  overflow:hidden;
  margin-bottom:$s-gutter-l - 4px;

  h1 {
    font-size:14px;
    font-weight:normal;
    font-family:$base-font-family;
    margin:0;
    margin-top:$s-gutter-s - 3px;
    line-height:.9;
    text-overflow:ellipsis;
    // transition: .1s color;
  }
  h2 {
    font-size:14px;
    font-weight:normal;
    margin:0;
    line-height:1;
    font-family:$base-font-family;
  }
  .category-title {
    @extend h2;
    display: block;
  }
  img + h2, img + .category-title, img + h1 {
    margin-top:7px;
  }
  h1 + img.teaser-pic {
    margin-top: 0;
  }
  p + img.teaser-pic {
    margin-top: 7px;
  }
  p {
    font-size:14px;
    margin:0;
    line-height:1.3;
    font-family:$base-font-family-sec;
    color:$c-main;

    &.large {
      font-size: 28px;
      font-family: $base-font-family;
      line-height: .95;

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
    &.bold {
      font-family: $base-font-family;
      line-height: 1.2;
    }
  }
  img.teaser-pic {
    width:100%;
    border-width:0;
    &.small {
      width:40%;
    }
    &.medium {
      width:85%;
    }
  }

  @include breakpoint(mobile) {
    h1 {
      font-size:22px;
    }
    img.teaser-pic {
      width:40%;
    }
    &.home-block-main {
      h1 {
        font-size:22px;
      }
      p {
        font-size:14px;
        line-height:1.1
      }
      img.teaser-pic {
        width:100%;
      }
    }
  }

}

a.home-block:hover {
  h1 {
    color:$c-highlight;
  }
}
.l-one-whole .home-block, .l-three-quarters .home-block {
  h1 {
    font-size:38px;
  }
  p {
    font-size:14px;
    max-width:700px;

    &.large {
      font-size: 28px;
      font-family: $base-font-family;
      line-height: .95;
      max-width: 850px;

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
    &.medium {
      font-size: 22px;
      font-family: $base-font-family;
      line-height: .95;

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
  }
  @include breakpoint(mobile) {
    h1 {
      font-size:22px;
    }
    img.teaser-pic {
      width:80%;
    }
  }
}
@include breakpoint(not-mobile){

  .l-one-half .home-block {
    h1 {
      font-size:30px;

      &.large {
        font-size: 38px;
      }
      &.small {
        font-size: 14px;
      }
    }
    p {
      font-size:14px;

      &.large {
        font-size: 28px;
      }
    }
  }
  .l-one-quarter .home-block {
    h1 {
      font-size:22px;

      &.large {
        font-size: 38px;
      }
      &.small {
        font-size: 14px;
      }
    }
    p {
      font-size:14px;

      &.large {
        font-size: 28px;
      }
    }
  }
  .l-one-third .home-block {
    h1 {
      font-size:22px;

      &.large {
        font-size: 38px;
      }
      &.small {
        font-size: 14px;
      }
    }
    p {
      font-size:14px;

      &.large {
        font-size: 28px;
      }
    }
  }
  .l-two-thirds .home-block {
    h1 {
      font-size:22px;

      &.large {
        font-size: 38px;
      }
      &.small {
        font-size: 14px;
      }
    }
    p {
      font-size:14px;

      &.large {
        font-size: 28px;
      }
    }
  }

  img + .home-block {
    margin-top:20px;
  }
}
