h1 + .accordion, h2 + .accordion, h3 + .accordion {
  margin-top:10px;
}

.accordion {
  border-top:1px solid $c-main;
  margin-bottom:$s-gutter-l;
  max-width:650px;

  .accordion-item {
    position:relative;
    height:40px;
    overflow:hidden;
    border-bottom:1px solid $c-main;
    box-sizing:border-box;

    a.accordion-item-head {
      display:block;
      position:absolute;
      top:0;
      left:0;
      height:30px;
      width:100%;
      overflow:hidden;
      padding-right:40px;
      box-sizing:border-box;

      h1.accordion-item-title {
        width:100%;
        font-size:14px;
        font-family:$base-font-family;
        margin:0;
        line-height:24px;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        font-weight: normal;
      }
      // &:after {
      //   content:"";
      //   display:block;
      //   position:absolute;
      //   bottom:18px;
      //   right:15px;
      //   height:5px;
      //   width:9px;
      //   background-image:url('../content/images/arrow-s_green.png');
      //   background-size:9px 5px;
      //   // @include transition(.4s transform ease-out);
      //   transform: rotate(180deg);
      //   visibility:hidden;
      // }
    }
    .accordion-item-content {
      position:static;
      margin:40px 0 25px 0;
      opacity:0;
      pointer-events:none;
    }
    &.is-visible {
      .accordion-item-content {
        position:absolute;
        top:40px;
        left:0;
        margin:0;
        opacity:1;
        pointer-events:auto;
        p {
          // margin-bottom:0;
        }
      }
      a.accordion-item-head {
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }
}
