.is-hidden {
    display: none!important;
    visibility: hidden;
}
.align-right {
  text-align: right;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.margin-bottom-s {
  margin-bottom: $s-gutter-s;
}
.margin-bottom-m {
  margin-bottom: $s-gutter-m;
}
.margin-bottom-l {
  margin-bottom: $s-gutter-l;
}
