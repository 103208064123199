.gallery {
  margin-top: 4px;

  .gallery-thb-link {
    display:inline-block;
    margin-right:4px;
    margin-bottom:4px;

    .gallery-thb-img {
      height:80px;
      border-width:0;
      // transition: .1s opacity;

      @include breakpoint(mobile) {
        height:50px;
      }
    }
    &:hover {
      .gallery-thb-img {
        opacity:.8;
      }
    }
  }
}
