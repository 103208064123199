.no-js .main-header { visibility: visible; }
.animate-in-header {
  animation: introheader 3s 1 normal cubic-bezier(0.085, 0.405, 0.305, 1.000) forwards;

  .mobilenav-header {
    animation: introheadermobile 2s 1 1s normal cubic-bezier(0.085, 0.405, 0.305, 1.000) forwards;
  }
  .header-logo {
    @include breakpoint(mobile) {
      animation: introheaderlogomobile 2s 1 1s normal cubic-bezier(0.085, 0.405, 0.305, 1.000) forwards;
    }
  }
}
.header-logo {
  display:block;
  width:27px;
  height:27px;
  position:fixed;
  top:75px;
  left:143px;
  z-index:5;
  transform: translateZ(0);

  img {
    width:100%;
    height:100%;

    &.header-logo-mobile {
      display: none;

      @include breakpoint(mobile) {
        display: inline;
      }
    }
    &.header-logo-desktop {
      display: inline;

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }
  @include breakpoint(mobile) {
    width:14px;
    height:14px;
    top:25px;
    left:50px;
  }
}
@keyframes introheader {
  0% {
    left: 200px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes introheadermobile {
  0% {
    top: -65px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes introheaderlogomobile {
  0% {
    top: -37px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 28;
    opacity: 1;
  }
}
.main-header {
  position:relative;
  height:140px;
  font-family: $base-font-family;
  margin:0 30px;
  visibility: hidden;
  z-index: 4;

  @include breakpoint(mobile) {
    height:auto;
    width:100%;
    // background: #000;
    margin: 0;
  }

  h1.header-name {
    margin:0;
    font-weight:normal;
    font-size:38px;
    font-family:$base-font-family;
    line-height:1;
    margin-bottom:-3px;
    margin-left: -1px;
    a.header-name-link {
      color:$c-main;
      text-decoration:none;
      font-family: inherit;
      &:hover, &:focus {
        color:$c-highlight;
      }
    }
  }

  .nav-wrap {
    position:absolute;
    top:72px;
    left:148px;
    font-family:$base-font-family;
    z-index: 3;
    color: $c-main;

    &.nav--static {
      @include breakpoint(mobile) {
        .nav-main-list.sec, .nav-main-list-third {
          display: none;
        }
      }
    }

    @include breakpoint(mobile) {
      position:fixed;
      top:0;
      left:0;
      width:100%;
      height: auto;
      max-height: 100%;
      background-color: #000;
      padding-top:65px;
      padding-left:70px;
      padding-bottom: 30px;
      color: #fff;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-x:hidden;
      box-sizing:border-box;
      -moz-box-sizing:border-box;

      transform: translateY(0);
      transition: .6s transform cubic-bezier(0.520, 0.000, 0.170, 1.000);
      &.mobile-hidden-translate {
        transform: translateY(-100%);
      }
    }
  }

  .icons-wrap {
    position:absolute;
    top:-22px;
    left:0;

    &.text-nav {
      top:-17px;

      a.header-icon-link {
        padding-right:8px;
        font-family: $base-font-family;
        font-size: 16px;
      }
    }

    a.header-icon-link {
      display:inline-block;
      color: $c-main;
      font-size: 24px;

      @include breakpoint(mobile) {
        color: #fff;
        font-size: 20px;
      }

      &:hover, &:focus {
        color: $c-highlight;
      }
    }


    @include breakpoint(mobile) {
      position: static;
      margin-bottom: 10px;
      // left:69px;
      // top:0px;

      a.header-icon-link {

      }
      &.text-nav {
        position: static;
        margin-bottom: 20px;

        a.header-icon-link {
          display: block;
          font-size:14px;
          margin-bottom: 5px;
          padding-right:4px;
        }
      }
    }
  }

  ul.nav-main-list {
    list-style-type:none;
    margin:0;
    padding:0;
    line-height:0;
    width:100%;
    position:relative;
    zoom: 1;
    transition: .2s opacity;

    .nav-main-item {
      display:inline-block;
      margin:0;
      margin-right:6px;
      font-size:28px;
      line-height:.9;
      //margin-bottom: 9px;

      @include breakpoint(mobile) {
        font-size: 20px;
        margin-bottom: 7px;
      }

      a.nav-main-link {
        display:inline-block;
        color: inherit;
        text-decoration:none;
        font-family: $base-font-family;
        // transition: .1s color;

        &:hover {
          color:$c-highlight;
        }
        &.is-active {
          color:$c-highlight;
        }
        &:focus {
          color:$c-highlight;
          outline: none;
        }
      }
    }
    &.sec {
      position:absolute;
      top:24px;
      left:0;
      opacity:0;
      filter: alpha(opacity=0);
      visibility: hidden;
      pointer-events:none;
      .nav-main-item {
        line-height:.9;
        font-size:20px;
        margin:0;
        margin-right:$s-gutter-s;
      }
      &.is-visible {
        display: inline;
      }

      @include breakpoint(mobile) {
        position: static;
        display: block;
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;
        // margin-top: 10px;
        transition: .5s max-height ease-out;
        transition-delay: 0;

        &.is-visible {
          max-height: 300px;
          display: block;
          transition: .5s max-height ease-out;
          transition-delay: 0;
        }
        .nav-main-item {
          display: block;
          margin-left: 20px;
          font-size: 14px;
          margin-bottom: 10px;

          &:first-child {
            margin-top: 10px;
          }
        }
      }
    }
    &.third {
      position:absolute;
      top:20px;
      left:0;
      opacity:0;
      filter: alpha(opacity=0);
      visibility: hidden;
      line-height:.85;
      pointer-events:none;
      .nav-main-item {
        font-size:16px;
        margin:0;
        margin-right:$s-gutter-s;
      }

      &.is-visible {
        display: inline;
      }

      @include breakpoint(mobile) {
        position: static;
        display: block;
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;
        // margin-top: 10px;
        transition: .5s max-height ease-out;
        transition-delay: 0;

        &.is-visible {
          max-height: 300px;
          display: block;
          transition: .5s max-height ease-out;
          transition-delay: 0;
        }
        .nav-main-item {
          display: block;
          margin-left: 20px;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
    &.is-visible {
      visibility: visible;
      opacity:1;
      filter: alpha(opacity=100);
      pointer-events:auto;
      transition: .8s opacity;
      transition-delay: .4s;
    }
  }
  ul.nav-main-sec {
    list-style-type:none;
  }

  @include breakpoint(mobile) {
    ul.nav-main-list {
      margin-bottom:10px;
      .nav-main-item {
        display:block;
        // line-height:.9;
        // margin-bottom:2px;
      }
      // &.sec {
      //   .nav-main-item {
      //     margin-bottom:2px;
      //   }
      // }
      // &.sec {
      //   .nav-main-item {
      //     margin-bottom:2px;
      //   }
      // }
    }
  }
}

.mobilenav-header {
  display:none;
  position:fixed;
  top:-65px;
  left:0;
  background: #000;
  height:65px;
  width:100%;
  z-index:4;

  .header-lion-mobile {
    display: none;
  }

  @include breakpoint(mobile) {
    display:block;

    .header-lion-mobile {
      display: block;
      position: absolute;
      top: 10px;
      left: 10px;
      height: 45px;

      img {
        height: 100%;
        width: auto;
      }
    }
  }
}
.mobilenav-btn {
  display: block;
  position:absolute;
  top:25px;
  margin: 0;
  left:70px;
  // font-family:$base-font-family;
  // color:#fff;
  // font-size:20px;
  // letter-spacing: -.05em;
  border:none;
  background: none;
  padding:0;
   //line-height: 1;

  img {
    height: 19px;
    width: auto;
  }

  &:focus {
    outline:none;
  }
  &.is-active {
    color:$c-highlight;
  }
}

.no-js {
  .main-header {
  }
  .nav-wrap {
    position:static;
    margin-left:150px;
    margin-top:75px;
  }
  ul.nav-main-list.sec, ul.nav-main-list.third {
    position:static;
    opacity:1;
    pointer-events:auto;

    .nav-main-item {
      opacity:1;
      pointer-events:auto;
    }
  }
}

.search-wrap {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:#fff;
  z-index:2;
  display:none;
}
.search-content {
  position:absolute;
  top:67px;
  left:178px;
  width:600px;

  @include breakpoint(mobile) {
    top:118px;
    left:57px;
    width:70%;
  }
}
.search-input {
  font-size:38px;
  font-family:$base-font-family;
  color:$c-main;
  border:none;
  background-color:transparent;
  width:100%;
  margin: 0;

  &:focus {
    outline:none;
  }
}
.search-close-btn {
  position:absolute;
  top:-20px;
  left:-6px;
  width: 30px;
  height: 30px;
  // font-family:$base-font-family-sec;

  &:hover, &:focus {
    path {
      fill: $c-highlight;
    }
  }
}
// .search-close-icon {
//   // font-size:30px;
//   width: 30px;
//   height: 30px;
//   display: inline-block;
// }
a.search-submit-btn {
  font-size:30px;
  color:$c-main;
  display:block;
  margin-top:-13px;
  font-family: $base-font-family;
  &:hover {
    color:$c-highlight;
  }
}

.mobilenav-toggleplane {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  // background: rgba(red, .5);
}


.mobile-subnav {
  margin-top: 65px;
  margin-bottom: -65px;
  background-color: rgba($c-main, .8);
  color: #fff;
  display: none;
  position: relative;
  z-index: 1;
  padding: 5px 0;

  @include breakpoint(mobile) {
    display: block;
  }

  &__list {
    list-style-type: none;
    margin-left: 70px;
    padding: 0;

    &--sec {
      margin-left: 20px;
    }
  }
  &__item {
    margin: 4px 0;
  }
  &__link:link, &__link:visited {
    font-family: $base-font-family;
    font-size: 14px;
    color: #fff;

    &.is-active {
      color: $c-highlight;
    }
  }
}
