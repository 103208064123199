.intro-animation {
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  z-index:99;
  display:none;
  pointer-events: none;

  .intro-flagge-img {
    width: auto;
    height: 60%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: none;

    @include breakpoint(mobile) {
      width: 80%;
      height: auto;
    }

    &.animate-in {
      display: block;
      animation: introflag 3s 1 normal cubic-bezier(0.085, 0.405, 0.305, 1.000) forwards;
    }
  }
}

@keyframes introflag {
  0% {
    // left: 200px;
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    // left: 0;
    transform: translateX(-100%);
    opacity: 0;
  }
}
.intro__book-bottom {
  position: absolute;
  top: -20%;
  left: -5%;
  width: 110%;
  height: 130%;
  z-index: 0;
  background-size: cover;
  background-image: url('/content/images/intro/buch-gruen.jpg');
  animation: bookback 2.5s 1 normal linear forwards;
}
.intro__book-top {
  // display: none;
  position: absolute;
  top: 50%;
  left: 10%;
  // width: 500px;
  width: 70%;
  // height: 619px;
  height: 0;
  padding-bottom: 100%;
  background-size: 100%;
  z-index: 2;
  background-image: url('/content/images/intro/buch-rot.png');
  background-repeat: no-repeat;
  animation: bookfront 3s 1 normal linear forwards;

  @include breakpoint(mobile) {
    width: 50%;
    padding-bottom: 62%;
  }
}
.intro__lion {
    width: 103px;
    height: 138px;
    display: none;
    position: absolute;
    left: 30px;
    top: 20px;
    z-index: 3;
    // display:none;
    img { width:100%; height:100%; }

    @include breakpoint(mobile) {
        width:30px;
        height:40px;
        left:7px;
        top:7px;
    }
}

@keyframes bookback {
  0% {
    opacity: 0;
    transform: translateY(10%) rotate(3deg);
  }
  20% {
    opacity: .7;
  }
  80% {
    opacity: .7;
  }
  100% {
    opacity: 0;
    transform: translateY(0) rotate(0);
  }
}
@keyframes bookfront {
  0% {
    opacity: 0;
    transform: translateY(-50%) rotate(-15deg) scale(1);
  }
  20% {
    opacity: .8;
  }
  100% {
    opacity: 0;
    transform: translateY(-50%) rotate(30deg) scale(1);
  }
}
