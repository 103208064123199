// body, html {
//   margin: 0;
//   padding: 0;

//   color: $c-white;
//   text-align: center;
//   line-height: 1.5;

//   background: $c-eigengrau;

//   /**
//    * PX fallback for IE8, Opera Mini and older Androids
//    * (px and not REM because of IE8)
//    */
//   font-size: 12px;
//   font-size: 1vw; /* 10% of the browser width */

//   @include breakpoint(tablet) {
//     font-size: 16px;
//     font-size: 1.5vw; /* 15% of the browser width */
//   }

//   @include breakpoint(mobile) {
//     font-size: 20px;
//     font-size: 2vw; /* 30% of the browser width */
//   }
// }

// a {
//   color: $c-silver;
// }

// .about {
//   // overflow: hidden;
//   // background-color: #16161D;
//   // height: 100vh;
//   //   position: relative;
//   overflow: hidden;   position: relative; padding: 5% 10%;
// }

html {
    min-height:100%;
    font:#{($base-font-size/16px)*1em}/#{$line-height-ratio} $base-font-family;
    color:$c-main;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-y:scroll;
}

html, body, .l-body {
    min-height:100%;
}
body {
    margin:0;
    overflow-x: hidden;
    background-size: cover;
    background-position: center;
    // font-size: 14px;
}

strong {
    font-family: $base-font-family;
    font-weight: normal !important;
}

h1, h2, h3, h4 { font-weight:400; font-family:$base-font-family; }

h1 {
    font-size:38px;
    line-height:.9;
    margin-top:0;
    margin-bottom:0;
    &.site-headline {
      margin-bottom:$s-gutter-s - 4px;
      &--search {
        margin-bottom:$s-gutter-s + 6px;
      }

      @include breakpoint(mobile) {
        font-size:32px;
      }
    }
    &.table-headline {
      margin-top: 40px;
      margin-bottom: 8px;
      @include breakpoint(mobile) {
        font-size:32px;
      }
    }
}
h2 {
    font-size:28px;
    margin-top:0;
    margin-bottom:$s-gutter-s - 11px;
}
h3 {
    font-size:22px;
    margin-top:0;
    margin-bottom:$s-gutter-s - 9px;
}
h4 {
    font-size:14px;
    margin-top:0;
    margin-bottom:$s-gutter-s - 7px;

    a:link, a:visited {
      font-family:$base-font-family;
    }
}
h1 + img, h2 + img, h3 + img, h4 + img {
    margin-top:10px;
}
.headline--large {
    @extend h1;
}
.headline--medium {
    @extend h2;
}
.headline--small {
    @extend h3;
}
.headline--tiny {
  @extend h4;
}
.headline + img {
    margin-top: 10px;
}
.headline + p {
  margin-top: 5px;
}
.headline--list {
  padding-left: 25px;
}
img + .headline {
  margin-top: 20px;
}
p {
    font-family:$base-font-family-sec;
    color:$c-main;
    font-size:14px;
    margin-top:0;
    &.site-paragraph {
        max-width:650px;
    }
    a:link, a:visited {
        text-decoration:underline;
    }
    strong {
        font-weight:600;
    }
    &.lead {
      font-family: $base-font-family;
      line-height: .95;
      font-size: 22px;
    }
}

.home-lead {
  font-family: $base-font-family;
  line-height: .9;
  margin-bottom: 10px;

  &--large {
    font-size: 48px;

    @include breakpoint(tablet) {
      font-size: 38px;
    }
    @include breakpoint(mobile) {
      font-size: 22px;
    }
  }
  &--medium {
    font-size: 38px;

    @include breakpoint(mobile) {
      font-size: 22px;
    }
  }
}
video + p, img + p {
    margin-top:$s-gutter-m;
}

a:link, a:visited {
    text-decoration:none;
    color:$c-main;
    font-family: $base-font-family-sec;
    // @include transition(.1s color);
}
a:hover, a:focus {
    color:$c-highlight;
}

.content-wrap {
    // max-width:750px;
    margin-left:150px;
    margin-top:45px;
    margin-bottom:50px;

    // transition: .3s margin-top $easeInOutCubic, .6s transform $easeInOutCubic;
    transition: .1s margin-top $easeInOutCubic;

    &:after {
        content:"";
        display:block;
        clear:both;
    }
    @include breakpoint(mobile) {
        margin-left:0px;
        margin-top:0px;
        transform: translateX(0);
        &.mobile-hidden-translate {
            transform: translateX(100%);
        }
    }
}

a.home-more-link {
    font-family: $base-font-family;
    color:$c-main;
    font-size:14px;
    &:hover { color:$c-highlight; }
}
.h1-home-mobile {
    font-size:32px;
    line-height:.85;
    margin-bottom:0;
}

img.i-small { width:27.3%; display:block; margin-bottom:$s-gutter-m; }
img.i-medium { width:42.6%; display:block; margin-bottom:$s-gutter-m;  }
img.i-textwidth { width:100%; max-width:650px; display:block; margin-bottom:$s-gutter-m;  }
img.i-large { width:100%; display:block; margin-bottom:$s-gutter-m;  }

img + span.caption {
  margin-top: -10px;
  margin-bottom: 15px;
}
img + img {
  margin-top: -6px;
}
span.caption {
  display: block;
  font-family: $base-font-family-sec;
  font-size: 14px;
}

.header-lion {
    width:103px;
    height:138px;
    position:fixed;
    left:30px;
    top:20px;
    z-index:3;
    display:none;
    img { width:100%; height:100%; }

    @include breakpoint(mobile) {
      top: 12px;
      left: 10px;
      height: 45px;
      width: 34px;
    }
}
.no-js .header-lion {
    display:block;
}


ul.styled-unordered-list {
    list-style-type:none;
    margin:0 0 20px 0;
    padding:0;
    font-family:$base-font-family-sec;
    font-size:14px;
    max-width:490px;
    li {
        position:relative;
        padding-left:20px;
        margin-bottom:$s-gutter-m;
        &:before {
            content:"— ";
            position:absolute;
            left:0;
            top:0;
        }
    }
    &--bold {
      li {
        font-family:$base-font-family;
      }
    }
    &--arrows {

      li {
        margin-bottom: 0;
        padding-left: 25px;
      }
      li:before {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1.3;
        color: #000;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e925";
      }
    }
}

ol.styled-ordered-list {
    font-family:$base-font-family-sec;
    font-size:14px;
    max-width:490px;
    margin:0 0 20px 0;
    padding-left:20px;
    li {
      margin-bottom:$s-gutter-m;
    }
    p {
      font-family:$base-font-family-sec;
      font-size:14px;
    }
    .list__headline {
      display: block;
    }

    &--bold {
      font-family:$base-font-family;
    }
    &--l {
      font-size: 22px;
      padding-left: 40px;
    }
    &--xl {
      font-size: 28px;
      padding-left: 45px;

      .list__headline {
        margin-bottom: -5px;
      }
    }
    &--xxl {
      font-size: 38px;
      padding-left: 65px;

      .list__headline {
        margin-bottom: -10px;
      }
    }
}

.styled-form-error {
    color:$c-red;
    font-family:$base-font-family-sec;
    font-size:14px;
    margin-bottom:$s-gutter-m;
    margin-top: -8px;
}

input.styled-form-input {
    border: none;
    border-bottom:1px solid $c-main;
    font-family:$base-font-family-sec;
    font-size:14px;
    padding:6px 8px 2px 0px;
    // padding: 0;
    color:#000;
    width:100%;
    margin-top:0;
    margin-bottom:$s-gutter-m;
    box-sizing:border-box;

    &:focus {
        outline:none;
        border-color:#000;
    }
    &::-webkit-input-placeholder { color: $c-main; }
    &:-moz-placeholder { color: $c-main; }
    &::-moz-placeholder { color: $c-main; }
    &:-ms-input-placeholder { color: $c-main; }

    &.error {
        border-color:$c-red;
    }
}

.styled-form-textarea {
    border: none;
    border-bottom:1px solid $c-main;
    font-family:$base-font-family-sec;
    font-size:14px;
    padding:4px 8px 2px 0px;
    // padding: 0;
    color:#000;
    width:100%;
    height:150px;
    margin-top:0;
    margin-bottom:$s-gutter-m;
    box-sizing:border-box;
    resize: none;

    &:focus {
        outline:none;
        border-color:#000;
    }
    &::-webkit-input-placeholder { color: $c-main; }
    &:-moz-placeholder { color: $c-main; }
    &::-moz-placeholder { color: $c-main; }
    &:-ms-input-placeholder { color: $c-main; }

    &.error {
        border-color:$c-red;
    }
}

.styled-form-submit {
    font-family:$base-font-family;
    font-size:14px;
    background:none;
    border:none;
    color:$c-main;
    padding:0;
    line-height:1;
    cursor: pointer;

    &:hover {
        color:$c-highlight;
        border-color:$c-highlight;
    }
}
.styled-radio-label {
    font-family:$base-font-family-sec;
    margin-bottom:$s-gutter-m;
    font-size:14px;
    position: relative;
    top: 1px;
}
.styled-form-label {
    font-family:$base-font-family-sec;
    font-size:14px;
    margin-right:10px;
    vertical-align: baseline;
}

.styled-table {
    border-collapse:collapse;
    font-size:14px;
    width:100%;
    margin-bottom:$s-gutter-l;

    tr {
        border-bottom:1px solid $c-main;
        th,td {
            padding:2px 10px 10px 0px;
            vertical-align: top;
        }
        th {
            font-weight:normal;
            text-align:left;
        }
        td {
            font-family:$base-font-family-sec;
        }
    }

  &--no-border tr {
    border-bottom: none;
  }
}

.styled-list {
    list-style-type:none;
    font-family:$base-font-family-sec;
    font-size:14px;
    line-height:1.6;
    padding:0;

    li {
        a:link, a:visited {
          position:relative;
        }
    }

    &.list-icon {
      li span {
        margin-right: 10px;
      }
    }
}

ul.list-team {
    list-style-type:none;
    padding:0;

    li.list-team-item {
        display:block;
        float:left;
        padding-right:10px;
        width:33.33%;
        box-sizing:border-box;

        @include breakpoint(tablet-portrait) {
          width:50%;
        }
        @include breakpoint(mobile-portrait) {
            width:100%;
        }
        h2 {
            font-size:14px;
            margin:2px 0 0 0;
        }
        h1 {
            font-size:22px;
            margin:0;
        }
        p {
            margin-top:0;
        }
        img.list-team-image {
            width:100%;
        }
    }
}

video.styled-video {
  width:100% !important;
  max-width:800px;
  height:auto !important;
  margin-bottom: 7px;
}

.backdrop-pic {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-position:center;
  background-size:cover;
  display:none;
}

.no-js .backdrop-pic {
    display:block;
}

// .icon-small {
//   // width: 24px;
//   // height: 24px;

//   svg {
//     width: 100%;
//     height: auto;
//   }
// }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}


/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */
audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
    resize: vertical;
}

.span-icon {
  display: block;

  span {
    margin-right: 10px;
  }
}
.span-bold {
  font-family: $base-font-family;
}


.pagination {
  width: 100%;

  &__arrow {
    font-size: 20px;
    position: relative;
    top: 1px;

    &--left {
      margin-right: 8px;
    }
    &--right {
      margin-left: 10px;
    }
  }
  &__nums {
    display: inline-block;

    &__item {
      padding: 0 .3em;
    }
  }
}
